import { Event, EventDispatcherConfig, toPayload } from "@hackler/sdk-core"
import { SDK_KEY_HEADER, SDK_TIME_HEADER } from "./config"
import { postRequest, sendBeacon } from "./HttpClient"

export type EventDispatcherResponse = {
  statusCode: number
}

export type EventDispatcherCallback = (response: EventDispatcherResponse) => void

export default class EventDispatcher {
  private readonly sdkKey: string
  private readonly headers: EventDispatcherConfig["headers"]
  private readonly dispatchUrl: string
  private readonly beaconDispatchUrl: string
  private readonly useBeacon: boolean

  constructor(sdkKey: string, config: EventDispatcherConfig) {
    this.sdkKey = sdkKey
    this.headers = config.headers
    this.dispatchUrl = config.dispatchUrl
    this.beaconDispatchUrl = config.beaconDispatchUrl || ""
    this.useBeacon = config.useBeacon
  }

  dispatch<T>(events: Event[], callback: EventDispatcherCallback): void {
    this._post(events, callback)
    // this._beacon(events, callback)
  }

  dispatchXhrOrBeacon<T>(events: Event[], callback: EventDispatcherCallback): void {
    if (this.useBeacon) {
      this._beacon(events, callback)
    } else {
      this._post(events, callback)
    }
  }

  _post(events: Event[], callback: EventDispatcherCallback): void {
    const { payload, headers } = this._createRequest(events)
    postRequest(this.dispatchUrl, payload, headers, callback)
  }

  _beacon(events: Event[], callback: EventDispatcherCallback): void {
    const { payload, headers } = this._createRequest(events)
    sendBeacon(this.beaconDispatchUrl, payload, headers, callback)
  }

  _createRequest(events: Event[]) {
    const payload = toPayload(events)
    const headers = {
      [SDK_KEY_HEADER]: this.sdkKey,
      [SDK_TIME_HEADER]: new Date().getTime().toString(),
      ...this.headers
    }
    return { payload, headers }
  }
}
