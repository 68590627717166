import {
  ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT,
  SDK_KEY_HEADER,
  SDK_NAME_HEADER,
  SDK_TIME_HEADER,
  SDK_VERSION_HEADER
} from "../config"
import { EventEmitter } from "events"
import { getRequest, Headers, Request, Response } from "../HttpClient"
import { Workspace, WorkspaceDto, WorkspaceFetcher } from "@hackler/sdk-core"

interface WorkspaceFetcherConfig {
  fetchUrl: string
  updateInterval: number
  headers: {
    [SDK_NAME_HEADER]: string
    [SDK_VERSION_HEADER]: string
  }
}

export default class PollingWorkspaceFetcher implements WorkspaceFetcher {
  private readonly sdkKey: string
  private eventEmitter: EventEmitter
  private isStarted: boolean
  private readonly readyPromise: Promise<void>
  private readyPromiseResolver: () => void
  private readyPromiseRejecter: (err: Error) => void
  private readonly updateInterval: number
  private readonly fetchUrl: string
  private readonly headers: WorkspaceFetcherConfig["headers"]
  private currentTimeout: any
  private errorRetryController: ErrorRetryController
  private currentRequest: Request | null
  private syncOnCurrentRequestComplete: boolean
  private isReadyPromiseSettled: boolean
  private httpGetRequest: typeof getRequest

  private workspace: Workspace | null = null

  constructor(
    sdkKey: string,
    httpGetRequest: typeof getRequest,
    eventEmitter: EventEmitter,
    config: WorkspaceFetcherConfig
  ) {
    this.sdkKey = sdkKey
    this.httpGetRequest = httpGetRequest
    this.eventEmitter = eventEmitter
    this.isStarted = false
    this.updateInterval = config.updateInterval
    this.fetchUrl = config.fetchUrl
    this.headers = config.headers
    this.readyPromiseResolver = (): void => {}
    this.readyPromiseRejecter = (): void => {}
    this.readyPromise = new Promise((resolve, reject) => {
      this.readyPromiseResolver = resolve
      this.readyPromiseRejecter = reject
    })
    this.currentTimeout = null
    this.errorRetryController = new ErrorRetryController()
    this.currentRequest = null
    this.syncOnCurrentRequestComplete = false
    this.isReadyPromiseSettled = false
  }

  onReady(): Promise<void> {
    return this.readyPromise
  }

  start(): void {
    if (!this.isStarted) {
      this.isStarted = true
      this.errorRetryController.reset()
      this.fetch()
    }
  }

  stop(): Promise<void> {
    this.isStarted = false
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout)
      this.currentTimeout = null
    }

    if (this.currentRequest) {
      this.currentRequest.abort()
      this.currentRequest = null
    }

    return Promise.resolve()
  }

  get(): Workspace | null {
    return this.workspace
  }

  private scheduleNextUpdate(): void {
    const currentBackoffDelay = this.errorRetryController.getDelay()
    const nextUpdateDelay = Math.max(currentBackoffDelay, this.updateInterval)

    this.currentTimeout = setTimeout(() => {
      if (this.currentRequest) {
        this.syncOnCurrentRequestComplete = true
      } else {
        this.fetch()
      }
    }, nextUpdateDelay)
  }

  private fetch(): void {
    const headers: Headers = {
      [SDK_KEY_HEADER]: this.sdkKey,
      [SDK_TIME_HEADER]: new Date().getTime().toString(),
      [SDK_NAME_HEADER]: this.headers[SDK_NAME_HEADER],
      [SDK_VERSION_HEADER]: this.headers[SDK_VERSION_HEADER]
    }

    this.currentRequest = this.httpGetRequest(this.fetchUrl, headers)

    const onRequestComplete = (): void => {
      this.onRequestComplete()
    }
    const onRequestResolved = (response: Response): void => {
      this.onRequestResolved(response)
    }
    const onRequestRejected = (err: any): void => {
      this.onRequestRejected(err)
    }

    this.currentRequest.responsePromise
      .then(onRequestResolved, onRequestRejected)
      .then(onRequestComplete, onRequestComplete)

    if (this.updateInterval > 0) {
      this.scheduleNextUpdate()
    }
  }

  private onRequestComplete(this: PollingWorkspaceFetcher): void {
    if (!this.isStarted) {
      return
    }

    this.currentRequest = null

    if (this.syncOnCurrentRequestComplete) {
      this.fetch()
    }
    this.syncOnCurrentRequestComplete = false
  }

  private onRequestResolved(response: Response): void {
    if (!this.isStarted) {
      return
    }

    if (typeof response.statusCode !== "undefined" && response.statusCode >= 200 && response.statusCode < 400) {
      this.errorRetryController.reset()
    } else {
      this.errorRetryController.countError()
    }

    if (response.body) {
      const dto = JSON.parse(response.body) as WorkspaceDto
      if (!this.workspace) {
        setTimeout(() => {
          this.eventEmitter.emit("ready")
        }, 0)
      }
      this.workspace = Workspace.from(dto)
      this.readyPromiseResolver()
    }
  }

  private onRequestRejected(err: any): void {
    console.error(err)
    if (!this.isStarted) {
      return
    }

    this.errorRetryController.countError()
  }

  close(): void {
    this.stop()
  }
}

class ErrorRetryController {
  private errorCount = 0

  getDelay(): number {
    if (this.errorCount === 0) {
      return 0
    }

    const baseWaitSeconds =
      ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT[
        Math.min(ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT.length - 1, this.errorCount)
      ]

    return baseWaitSeconds * 1000 + Math.round(Math.random() * 1000)
  }

  countError(): void {
    if (this.errorCount < ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT.length - 1) {
      this.errorCount++
    }
  }

  reset(): void {
    this.errorCount = 0
  }
}
